<template>
  <div>
    <div>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Receipt Status</h1>
      </div>

      <div class="title-tabs mt-3 d-flex align-items-center">
        Status
        <div class="ml-auto">
          <InputSelectLanguage ref="inputLang" @changeLang="changeLanguage" />
        </div>
      </div>
      <div v-if="isLoading">
        <OtherLoading />
      </div>
      <template v-else>
        <div class="bg-white p-3">
          <b-row class="align-items-center row-gap-lg">
            <b-col cols="2" lg="1" class="text-center"> Sort Order</b-col>
            <b-col cols="2"> </b-col>
            <b-col cols="4">Display Name </b-col>
            <b-col cols="4" class="text-center"> </b-col>

            <div class="break-normal"></div>
            <template v-for="(field, index) of list">
              <b-col
                cols="2"
                lg="1"
                :key="'sort-order' + field.id + '-' + index"
                class="text-center"
              >
                {{ index + 1 }}
              </b-col>
              <b-col cols="2" :key="'name' + field.id + '-' + index">
                <InputText
                  type="text"
                  placeholder=""
                  disabled
                  class="mb-0"
                  :v="$v.list.name"
                  :isValidate="$v.list.$each.$iter[index].name.$error"
                  v-model="field.name"
              /></b-col>
              <b-col cols="4" :key="'display-name' + field.id + '-' + index">
                <InputText
                  type="text"
                  placeholder="Display Name"
                  class="mb-0"
                  v-model="
                    field.receipt_scaner_status_language[languageIndex].name
                  "
              /></b-col>
              <b-col cols="4" :key="'desc' + field.id + '-' + index"> </b-col>

              <div class="break-normal" :key="'break-row' + index"></div>
            </template>
          </b-row>
        </div>
      </template>

      <FooterAction @submit="submit()" routePath="/setting/personal" />
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
// import DataType from "./type.js";
import InputSelectLanguage from "@/components/inputs/InputSelectLanguage";
export default {
  components: { OtherLoading, InputSelectLanguage },
  meta: { label: "custom-label" },
  validations() {
    return {
      list: {
        $each: {
          name: {
            required,
          },
        },
      },
    };
  },

  data() {
    return {
      list: [],
      listDelete: [],
      updateList: [],
      isLoading: true,
      pageDetail: {
        name: "",
        header: "",
        updated: "",
      },
      isDefault: null,
      languageList: [],
      languageIndex: 0,
    };
  },
  async created() {
    // const page = DataType.find((el) => el.id == this.$route.params.id);
    // this.pageDetail = page;
  },
  destroyed() {},
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const res = await this.axios(
        `setting/display_language/receipt_scaner_status`
      );

      if (res.data.detail[0].receipt_scaner_status_language.length == 0) {
        for (const element of this.$refs.inputLang.languageList) {
          for (const field of res.data.detail) {
            const label = {
              id: 0,
              language_id: element.id,
              field_choice_id: field.id,
              name: field.name,
            };
            field.receipt_scaner_status_language.push(label);
          }
        }
      }

      this.list = res.data.detail;
      this.isLoading = false;
    },

    async submit() {
      try {
        this.$v.list.$touch();
        if (this.$v.list.$error) return;
        this.$bus.$emit("showLoading");

        const res = await this.axios.post(
          `setting/display_language/receipt_scaner_status`,
          this.list
        );
        this.getList();
        this.$bus.$emit("hideLoading");
        if (res.data.result == 1)
          this.successAlert().then(() => {
            this.$router.push("/setting");
          });
        else this.errorAlert();
      } catch (error) {
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    async switchProfileIndex(items, index, val) {
      if (index == 0 && !val) {
        return;
      }

      this.list.splice(index, 1);
      index = val ? index + 1 : index - 1;

      this.list.splice(index, 0, items);
      var index = 0;
      for (const fields of this.list) {
        fields.sort_order = index;
        index++;
      }
      this.$nextTick(() => {
        this.list = [...this.list];
        // this.sortArray();
      });
    },
    handleDefaultChange(val, index) {
      this.$nextTick(
        () =>
          (this.list = [
            ...this.list.map((el, i) => {
              if (i == index) return el;
              return { ...el, isDefault: 0 };
            }),
          ])
      );
    },

    changeLanguage(val) {
      let index = this.list[0].receipt_scaner_status_language.findIndex(
        (el) => el.language_id == val
      );
      if (index != -1) return (this.languageIndex = index);
      let langList = this.$refs.inputLang.languageList;
      for (const list of this.list) {
        if (list.receipt_scaner_status_language.length != langList.length) {
          list.receipt_scaner_status_language = [
            ...list.receipt_scaner_status_language,
            ...langList
              .filter((el) =>
                list.receipt_scaner_status_language.find(
                  (a) => a.language_id != el.id
                )
              )
              .map((el) => {
                return {
                  id: 0,
                  language_id: el.id,
                  field_choice_id: list.id,
                  name: "",
                };
              }),
          ];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
  cursor: pointer;
}
</style>
